import axios from 'axios'

axios.defaults.baseURL = "http://localhost:3000";

axios.interceptors.response.use(
    res => res.data,
    error => Promise.reject(error)
)

export const aMap2BaiduPointAsync = (params) => axios.get('/api2/geoconv/v1/?',{
    params:params
});
