import React, {Component} from 'react';
import { Layout, Menu, Breadcrumb, Icon, } from 'antd';
import OMap from '../../components/map/OMap';
import './Index.css';

const { Header, Content, Footer, Sider, } = Layout;

class Index extends Component{

    constructor(props){
        super(props)
    }
    render(){
        const now = new Date();
        return (
            <div className="home" style={{ padding: '0 50px' }}>
                <Breadcrumb style={{ margin: '16px 0',fontsize: '15px' }}>
                    <Breadcrumb.Item>Today {now.getFullYear()}/{now.getMonth()+1}/{now.getDate()} .</Breadcrumb.Item>
                </Breadcrumb>
                <OMap></OMap>
            </div>
        )
    }
}

export default Index;