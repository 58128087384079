import React, {Component} from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Card } from 'antd';

import './Login.css';

const Login = () =>{

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className="Login">
            <Row>
                <Col span={8}></Col>
                <Col span={8}>
                    <Card style={{ marginTop: 16 }} title="登录" extra={<a href="/">&nbsp;返回&nbsp;》&nbsp;</a>}>
                        <div style={ {  }}>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your Username!' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox>Remember me</Checkbox>
                                    </Form.Item>

                                    <a className="login-form-forgot" href="">
                                        Forgot password
                                    </a>
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button" disabled>
                                        Log in
                                    </Button>
                                    {/*<a href="">&nbsp;&nbsp;register now!</a>*/}
                                </Form.Item>
                            </Form>
                        </div>
                    </Card>
                </Col>
                <Col span={8}></Col>
            </Row>
        </div>
    )
}

export default Login;