import React, {Component} from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Card } from 'antd';

import './Async.css';

const Async = () => {

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <div className="Async">
            <Row>
                <Col span={4}></Col>
                <Col span={16}>
                    <Card title="设置" extra={<a href="/">返回&nbsp;》</a>}>
                        <Card type="inner" title="baidu config" extra={<Button type="primary" htmlType="submit">Save</Button>}>
                            <Form
                                name="wrap"
                                labelCol={{
                                    flex: '100px',
                                }}
                                labelAlign="right"
                                labelWrap
                                wrapperCol={{
                                    flex: 1,
                                }}
                                colon={false}
                            >
                                <Form.Item
                                    label="账号"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="密码"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Card>
                        {/*<Card*/}
                        {/*    style={{*/}
                        {/*        marginTop: 16,*/}
                        {/*    }}*/}
                        {/*    type="inner"*/}
                        {/*    title="amap config"*/}
                        {/*    extra={<a href="#">Save</a>}*/}
                        {/*>*/}
                        {/*    Inner Card content*/}
                        {/*</Card>*/}
                        {/*<Card type="inner" title="qq config">*/}
                        {/*    Inner Card content*/}
                        {/*</Card>*/}
                        {/*<Card type="inner" title="google config">*/}
                        {/*    Inner Card content*/}
                        {/*</Card>*/}
                    </Card>
                </Col>
                <Col span={4}></Col>
            </Row>
        </div>
    )
}

export default Async;