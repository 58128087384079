import React, {Component} from 'react';
import {Routes,Route} from "react-router-dom";
import {Layout} from "antd";

import Index from "../../page/index/Index";
import Login from "../../page/login/Login";

import './Home.css';
import Async from "../../page/async/Async";

const { Content } = Layout;

class Home extends Component{

    constructor(props){
        super(props)
    }
    render(){
        return (
            <Content style={{ padding: '0 50px'}}>
                    <Route exact path="/" component={ Index }/>
                    <Route exact path="/login" component={ Login }/>
                <Route exact path="/async" component={ Async}/>
            </Content>
        )
    }
}

export default Home;