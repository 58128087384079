import React, {Component} from 'react';
import {Avatar, Icon, Layout, Menu, Dropdown, Space } from 'antd';
import { UserOutlined,DownOutlined } from '@ant-design/icons';

import './Head.css';
import {print} from "../util/b2map";

const { Header} = Layout;
const items = [
    {
        label: '修改密码',
        key: '0',
        disabled: true
    },
    {
        label: "设置",//<a href="/async">设置</a>,
        key: '1',
        disabled: false
    },
    {
        type: 'divider'
    },
    {
        label: <a href="/login">登录</a>,
        key: '3',
    },
    {
        label: '退出',
        key: '4',
    }
];
const onClick = ({ key }) => {
    print(`Click on item ${key}`);
};
const Head = () => {
    return (
        <Header className="Header">
            <Dropdown
                menu={{
                    items,
                    selectable: true,
                    onClick
                }}
                trigger={['click']}
                placement="bottomLeft"
                arrow
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {/*<Avatar icon={<UserOutlined />} shape="circle"/>*/}
                        {/*<DownOutlined />*/}
                    </Space>
                </a>
            </Dropdown>
            {/*<Avatar shape={ 'square' } alt={ '头像' } style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon="user">User</Avatar>*/}
            {/*<Avatar style={{color: '#f56a00',backgroundColor: '#fde3cf',}}>U</Avatar>*/}
            <span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>分享我去的世界
            </span>
        </Header>
    )
}
export default Head;