import React, {Component} from 'react';
import {Map, Marker, NavigationControl,ZoomControl, InfoWindow, ScaleControl,MapApiLoaderHOC, MapTypeControl,CityListControl} from 'react-bmapgl'
import './OMap.css';
import fav from '../../assets/fav-icon-map_8991418.png';
import {aMap2BaiduPointAsync} from '../util/request'
import baiduFav from '../../mock/map_baidu_favdata_20230504.json'
import amapPoisFav from '../../mock/amap-favepois-103444836.json'
import amapFav from '../../mock/map_a_favdata_20230512.json'
import {amap2point, baidu2point, print} from "../util/b2map";

class OMap extends Component{
    constructor(props){
        super(props)
        this.state = {
            marker : {
                icon : 'loc_blue'
            },
            fav: [],
            afav:[],
            fav2:[
                {
                    "lng": 108.2886058161035,
                    "lat": 34.21663609987007,
                    "label": "测试1"
                },
                {
                    "lng": 108.9986058161035,
                    "lat": 34.53663609987007,
                    "label": "测试2"
                },
                {
                    "lng": 108.5986058161035,
                    "lat": 34.52663609987007,
                    "label": "测试2"
                }],
            map:{
                defaultOptions:{

                },
                displayOptions:{

                }
            }
        }
        this.baiduFavDataRead()
        this.amapFavDataRead()
        // console.log("point: 120.68077,28.683236 | 13266368.370000,3415381.810000")
        // console.log(baidu2point({lng:13266368.370000,lat:3415381.810000}))
    }
    componentDidMount() {
        this.initFav(this.state.afav)
        this.initFav(this.state.fav)
    }
    componentWillUnmount(){
        this.setState({fav:[]})
        this.setState({afav:[]})
    }
    render(){
        return (
            <div>
                <Map ref={ref => {this.map = ref.map}}
                     center={{lng: 108.9886058161035, lat: 34.21663609987007}}
                     style={{ height: 600 }}
                     zoom={6}
                     heading={0}
                     tilt={0}
                     onClick={e => this.map_on_click(e)}
                     defaultOptions={this.state.map.defaultOptions}
                     displayOptions={this.state.map.displayOptions}
                     mapStyleV2={{ styleId: 'ae5aef9d4bd22b7e1ce97e568b478dec' }}
                     mapType={"normal"}
                     enableScrollWheelZoom>
                    <Marker position={{lng: 108.9886058161035, lat: 34.21663609987007}}
                            onMouseover={ this.marker_on_mouseover }
                            onClick={ this.marker_on_click }
                            icon={ this.state.marker.icon }/>
                    <MapTypeControl />
                    <ScaleControl />
                    <ZoomControl />
                </Map>
            </div>
        )
    }
    initFav(favD){
        for(let i=0;i<favD.length;i++){
            let a = favD[i]
            this.BMapGL = window.BMapGL;
            const point = new this.BMapGL.Point(a.lng,a.lat);
            const myIcon = new this.BMapGL.Icon(fav, new this.BMapGL.Size(11, 12), {
                // 指定定位位置。
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
                // 图标中央下端的尖角位置。
                anchor: new this.BMapGL.Size(0, 0),
                // 设置图片偏移。
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。
                imageOffset: new this.BMapGL.Size(0, 0)   // 设置图片偏移
            });
            const marker = new this.BMapGL.Marker(point,{icon: myIcon});
            this.map.addOverlay(marker);
        }
    }
    marker_on_mouseover(e){
        // console.log(e.type)
    }
    marker_on_click(e){
        // console.log(e)
        this.BMapGL = window.BMapGL;
        var point = new this.BMapGL.Point(e.latLng.lng,e.latLng.lat);
        var content = "西安";
        var label = new this.BMapGL.Label(content, {       // 创建文本标注
            position: point,                          // 设置标注的地理位置
            offset: new this.BMapGL.Size(10, 20)           // 设置标注的偏移量
        });
        var myIcon = new this.BMapGL.Icon(fav, new this.BMapGL.Size(23, 25), {
            // 指定定位位置。
            // 当标注显示在地图上时，其所指向的地理位置距离图标左上
            // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
            // 图标中央下端的尖角位置。
            anchor: new this.BMapGL.Size(10, 25),
            // 设置图片偏移。
            // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您
            // 需要指定大图的偏移位置，此做法与css sprites技术类似。
            imageOffset: new this.BMapGL.Size(0, 0)   // 设置图片偏移
        });
        var marker = new this.BMapGL.Marker(point, {icon: myIcon});
        this.map.addOverlay(marker);
    }
    map_on_click(type, target, latlng, pixel, overlay){
        console.log(type)
    }
    baiduFavDataRead(){
        let favs = baiduFav.sync.newdata
        let new_favs = []
        for(let i=0;i < favs.length;i++){
            if(favs[i].status == '100' && favs[i].action != 'del' && favs[i].detail.data != false && favs[i].detail.data.extdata != undefined)
                new_favs.push(baidu2point({
                    "lng": favs[i].detail.data.extdata.geoptx,
                    "lat": favs[i].detail.data.extdata.geopty,
                    "label": favs[i].detail.data.extdata.name
                }))
        }
        this.state.fav = new_favs
    }
    amapFavePoisRead(){
        let items = amapPoisFav.items
        let a_favs = []
        let ff = {}
        for(let i=0;i < items.length;i++){
            let _fav =  items[i].data
            if(items[i].id != undefined)
                ff = amap2point(_fav.point_x,_fav.point_y)
            a_favs.push({
                "lng": ff.lng,
                "lat": ff.lat,
                "label": _fav.name,
                "create_time": _fav.create_time
            })
        }
        this.state.afav = a_favs;
    }
    amapFavDataRead(){
        let items = amapFav.items
        let a_favs = []
        for(let i=0;i < items.length;i++){
            let _fav = items[i]
            a_favs.push({
                "lng": _fav.lng,
                "lat": _fav.lat,
                "label": _fav.name,
                "create_time": _fav.create_time
            })
        }
        this.state.afav = a_favs;
    }
    aMap2BaiduPoint = async(points) =>{
        const res = await aMap2BaiduPointAsync({
            coords: points,
            from: 3,
            to: 5,
            ak: 'YRbdPobyMk4KK9MsmqPcTk2un6w7iWe9'
        });
        print(res)
        res.result.forEach(a=>{this.state.afav.push(a)})
        this.initAFav()
    }

}
// export default OMap
export default MapApiLoaderHOC({ak: 'YRbdPobyMk4KK9MsmqPcTk2un6w7iWe9'})(OMap)
