import React, {Component} from 'react';
import {Layout} from "antd";

import './Foot.css';

const { Footer } = Layout;

class Foot extends Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <Footer className="Foot">
                Copyright ©2023-2035 WWW.SHAREWORLD.VIP All Reserved | <a target={"_blank"} href={'http://www.beian.gov.cn/portal/recordQuery?token=e749a8a9-899b-49c4-b058-2713c2f7bcb8'}>沪ICP备18013056号</a>
            </Footer>
        )
    }
}

export default Foot;