//public.js function searchByPoint pointToLngLat
//2023-05-08
export const baidu2point = function (a){
    let gQ = [[1.410526172116255E-8, 8.98305509648872E-6, -1.9939833816331, 200.9824383106796, -187.2403703815547, 91.6087516669843, -23.38765649603339, 2.57121317296198, -0.03801003308653, 1.73379812E7], [-7.435856389565537E-9, 8.983055097726239E-6, -0.78625201886289, 96.32687599759846, -1.85204757529826, -59.36935905485877, 47.40033549296737, -16.50741931063887, 2.28786674699375, 1.026014486E7], [-3.030883460898826E-8, 8.98305509983578E-6, 0.30071316287616, 59.74293618442277, 7.357984074871, -25.38371002664745, 13.45380521110908, -3.29883767235584, 0.32710905363475, 6856817.37], [-1.981981304930552E-8, 8.983055099779535E-6, 0.03278182852591, 40.31678527705744, 0.65659298677277, -4.44255534477492, 0.85341911805263, 0.12923347998204, -0.04625736007561, 4482777.06], [3.09191371068437E-9, 8.983055096812155E-6, 6.995724062E-5, 23.10934304144901, -2.3663490511E-4, -0.6321817810242, -0.00663494467273, 0.03430082397953, -0.00466043876332, 2555164.4], [2.890871144776878E-9, 8.983055095805407E-6, -3.068298E-8, 7.47137025468032, -3.53937994E-6, -0.02145144861037, -1.234426596E-5, 1.0322952773E-4, -3.23890364E-6, 826088.5]];
    let aH = [1.289059486E7, 8362377.87, 5591021, 3481989.83, 1678043.12, 0];

    function K(a, b) {
        return {
            lng: a,
            lat: b,
            of: "inner"
        }
    };

    function YK(a, b) {
        let c = b[0] + b[1] * Math.abs(a.lng), d = Math.abs(a.lat) / b[9];
        d = b[2] + b[3] * d + b[4] * d * d + b[5] * d * d * d + b[6] * d * d * d * d + b[7] * d * d * d * d * d + b[8] * d * d * d * d * d * d;
        c = c * (0 > a.lng ? -1 : 1);
        d = d * (0 > a.lat ? -1 : 1);
        return new K(c, d)
    };

    function ub(a) {
        let b, c;
        b = new K(Math.abs(a.lng),Math.abs(a.lat));
        for (let d = 0; d < aH.length; d++)
            if (b.lat >= aH[d]) {
                c = gQ[d];
                break
            }
        a = YK(a, c);
        a = new K(a.lng.toFixed(6),a.lat.toFixed(6))
        return a
    };

    return ub(a)
}

export const print = function (a){
    console.log(a)
    return a
}

export const amap2point = function (x,y){
    let t = 256
        , a = -85.0511287798
        , e = 85.0511287798
        , i = -180
        , n = 180
        , r = 6378137
        , o = Math.PI //3.141592653589793
        , s = {};

    function PixelsToLatLong(a, e, i) {
        var n = 2 * o * r
            , s = n / ((1 << i) * t)
            , m = a * s - n / 2
            , d = n / 2 - e * s
            , p = o / 2 - 2 * Math.atan(Math.exp(-d / r));
        p *= 180 / o;
        var f = m / r;
        return f *= 180 / o,
            {
                la: p,
                lo: f
            }
    };

     function LngLat(_m){
         let t = _m.lo,e=_m.la;
         let x = Math.round(1e6 * t) / 1e6,
            y = Math.round(1e6 * e) / 1e6;
         return {
             lng: x,
             lat: y
         }
    }

    return LngLat(PixelsToLatLong(x,y,20));
}