import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb, Icon, } from 'antd';
import { BrowserRouter as Router,Routes,Route,Link } from 'react-router-dom';

import './App.css';

import Foot from './components/layout/Foot';
import Head from './components/layout/Head';
import Home from "./components/layout/Home";
import Index from "./page/index/Index";

const { Header, Content, Footer, } = Layout;

class App extends Component {

    constructor(props){
        super(props)
    }
    componentDidMount(){

    }
    componentWillUnmount(){

    }
    render() {
        return (
            <div className="App">
                <Router>
                    <Layout>
                        <Head/>
                        <Home/>
                        <Foot/>
                    </Layout>
                </Router>
            </div>
        );
    }
}

export default App;
